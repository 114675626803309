import { useRouter } from "next/router";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import { HiArrowRight as ArrowRightIcon } from "react-icons/hi2";
import { useDispatch } from "react-redux";

import { EdgeAPI } from "@/api/Edge";
import { WaveAPI } from "@/api/Wave";
import { useFetch } from "@/hooks/useFetch";
import {
  LiteCompany,
  LiteCompanySearchAPI,
  PremiumCompany,
  PremiumCompanySearchAPI,
} from "@/model/general";
import { SetSnapshotData } from "@/slice/SnapshotSlice";
import { mergeTwoObjectArray } from "@/util";
import { getTickerUrl } from "@/util/article";

import { LoadingAnimation } from "../LoadingAnimation";

type Props = {
  close: () => void;
  isFolikoInsights?: boolean;
  isHomeSearch: boolean;
  isOnboarding?: boolean;
  isPublic: boolean;
  isSearchLite: boolean;
  overrideAction?: string;
  searchTerm: string;
  setSearchSelection?: Dispatch<SetStateAction<PremiumCompany | undefined>>;
  setSelectedOnboarding?: (company: PremiumCompany) => void;
};

export const Company = ({
  setSearchSelection,
  close,
  searchTerm,
  overrideAction,
  isHomeSearch,
  isPublic,
  isFolikoInsights,
  isSearchLite,
  isOnboarding,
  setSelectedOnboarding,
}: Props) => {
  const router = useRouter();
  const dispatch = useDispatch();
  const [searchResults, setSearchResults] = useState<
    PremiumCompany[] | undefined
  >([]);
  const {
    loading,
    data,
  }: {
    data: PremiumCompanySearchAPI | LiteCompanySearchAPI;
    error: Error | undefined;
    loading: boolean;
  } = useFetch(
    isPublic
      ? WaveAPI.FOLIKO_COMPANY_PUBLIC_SEARCH
      : EdgeAPI.SearchFolikoPremiumCompany,
    { NameFilter: searchTerm },
    [searchTerm]
  );

  const {
    data: companiesFromTicker,
    loading: companiesFromTickerLoading,
    error: companiesFromTickerError,
  } = useFetch(
    EdgeAPI.GetCompaniesFromTickers,
    searchTerm ? { tickers: [searchTerm] } : {},
    [searchTerm],
    !!searchTerm
  );

  useEffect(() => {
    let finalResult: LiteCompany[] | PremiumCompany[] | undefined = [];
    if (
      !companiesFromTickerLoading &&
      companiesFromTicker &&
      companiesFromTicker.length > 0
    ) {
      const companySearchResult = data?.results;
      if (companySearchResult && companySearchResult?.length > 0) {
        finalResult = mergeTwoObjectArray(
          companiesFromTicker,
          companySearchResult
        ) as LiteCompany[];
      }
    } else {
      finalResult = data?.results as PremiumCompany[];
    }

    if (finalResult) {
      const result = finalResult.map((company) => {
        return "id" in company
          ? {
              Id: company.id,
              IsDelisted: false,
              IsPennyStock: false,
              Name: company.name,
              Sector: company.sector,
              Ticker: company.ticker.toUpperCase(),
            }
          : company;
      });
      setSearchResults(result);
    }
  }, [companiesFromTicker, companiesFromTickerLoading, data?.results]);

  const handleCompanySelection = (company: PremiumCompany) => {
    if (!!setSearchSelection) {
      setSearchSelection(company);
      close();
      if (isHomeSearch) {
        router.push(getTickerUrl(company.Ticker));
      }
      if (isSearchLite) {
        if (router.query.ticker && router.query.ticker[0] !== company.Ticker) {
          dispatch(SetSnapshotData(undefined));
        }
        router.push(getTickerUrl(company.Ticker));
      }
    } else {
      if (isFolikoInsights && isOnboarding && setSelectedOnboarding) {
        setSelectedOnboarding(company);
        return;
      }

      if (isFolikoInsights) {
        router.push(getTickerUrl(company.Ticker)).then(() => close());
      } else {
        router.push(`/company/${company.Ticker}`).then(() => close());
      }
    }
  };

  return (
    <Scrollbars className="flex-1 overflow-y-auto">
      {loading ? (
        <LoadingAnimation />
      ) : searchResults?.length ?? 0 > 0 ? (
        searchResults?.map((company) => (
          <div
            role="search-result"
            data-testid={`search-result-${company.Id}`}
            key={company.Id}
            className={`company-list-item flex justify-between px-4 py-2 text-sm font-normal group z-50 ${
              !isSearchLite
                ? "hover:text-white hover:bg-blue-light"
                : "hover:text-white hover:bg-lite-tertiary"
            }`}
            onClick={() => {
              handleCompanySelection(company);
            }}
          >
            <div className="flex space-x-2 cursor-pointer">
              <div
                className={`${
                  isSearchLite
                    ? "text-white font-bold"
                    : "font-medium text-blue-light group-hover:text-white"
                } min-w-14`}
              >
                {company.Ticker}
              </div>
              <div className={`${isSearchLite && "text-white"}`}>
                {company.Name}
              </div>
            </div>
            {!isSearchLite && (
              <div className="inline-flex items-center space-x-1 text-xs font-normal text-white">
                <div>{overrideAction ?? "search"}</div>
                <ArrowRightIcon className="w-4 h-4" />
              </div>
            )}
          </div>
        ))
      ) : (
        <LoadingAnimation
          animate={false}
          message={
            searchTerm.length == 0
              ? "Please type for suggestions"
              : "No records found"
          }
          className="text-center font-semibold px-2"
        />
      )}
    </Scrollbars>
  );
};
